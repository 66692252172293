import React from "react"
import {
  Nav,
  NavElement,
  NavLink,
  NavList,
  NavTitle,
  TitleElement,
  PRLink,
  Logo,
  NavSubTitle,
  FlexWrapper,
} from "./styles"
import pagesList from "../pagesList"
import LogoPR from "src/images/logoPR24.png"
import microphone from "src/images/microphone.png"
import ModalButton from "../../ModalButton"
import RadioBG from "../../../images/RadioBaczynskiBG.png"
import { Link } from "gatsby"

const animation = {
  exit: {
    x: "100%",
    transition: {
      ease: "easeInOut",
      duration: 0.7,
    },
  },
  enter: {
    x: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.7,
    },
  },
}

const Menu = ({ handleMenuToggle }) => {
  return (
    <Nav initial="exit" animate="enter" exit="exit" variants={animation}>
      <Link
        to="/"
        style={{ textDecoration: "none" }}
        onClick={handleMenuToggle}
      >
        <NavTitle>
          Krzysztof Kamil <br /> <TitleElement>Baczyński</TitleElement>
        </NavTitle>
      </Link>
      <NavList>
        {pagesList.map((page, index) => (
          <NavElement key={page.path}>
            <NavLink
              activeStyle={{ opacity: 1 }}
              fade
              onClick={handleMenuToggle}
              to={`/${page.path}`}
              partiallyActive={true}
            >
              {`${index + 1}. `}
              {page.name}
            </NavLink>
          </NavElement>
        ))}
        <NavElement>
          <NavLink
            aria-label="zrodla"
            role="menuitem"
            activeClassName="active"
            // activeStyle={{ opacity: "1" }}
            fade="true"
            to={`/zrodla`}
            partiallyActive={true}
            onClick={handleMenuToggle}
          >
            Źródła
          </NavLink>
        </NavElement>
      </NavList>
      <FlexWrapper>
        <PRLink href="https://www.polskieradio24.pl">
          <Logo src={LogoPR} alt="Logo Polskiego Radia" />
        </PRLink>
        <ModalButton
          photo={RadioBG}
          title={"Radio Baczyński"}
          content={`Wiersze Krzysztofa Kamila Baczyńskiego znają nie tylko czytelnicy poezji. Znają je również – być może czasami nieświadomie – melomani. 
„Znów wędrujemy ciepłym krajem, malachitową łąką morza”. „Jeno wyjmij mi z tych oczu, szkło bolesne – obraz dni”. „Tańczą panowie, niewidzialni, na moście w Avignon”. „Chcę czerwień zerwać z kwiatów polnych, czerwienią nocy spalić krew, jak piersi nieba chcę być wolny, w chmury się wbić, w koronach drzew!”.
<br/><br/>
To fragmenty niektórych tylko – poddanych wokalnej interpretacji – wierszy znakomitego poety. W Radiu Baczyński takich piosenek powstałych do słów autora „Elegii o chłopcu polskim” znajdziemy znacznie więcej – ponad sto dwadzieścia kompozycji! Co istotne: reprezentują one wiele różnorodnych, oddalonych od siebie muzycznych światów. <br/>
Wśród wykonawców: Ewa Demarczyk, Halina Wyrodek, Stanisława Celińska, Gaba Kulka, Grzegorz Turnau, Leszek Długosz, Mela Koteluk i zespół Kwadrofonik, Roman Kołakowski, Janusz Radek, Sławomir Zygmunt. A także: Chór Polskiego Radia, Chór Narodowego Forum Muzyki, The Ceez, Budka Suflera i zespół Piersi.`}
        >
          <NavSubTitle>
            <img src={microphone} alt="Radio Baczyński" />
            <TitleElement>Radio</TitleElement>
            <br /> Baczyński
          </NavSubTitle>
        </ModalButton>
      </FlexWrapper>
    </Nav>
  )
}

export default Menu
