// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legenda-js": () => import("./../../../src/pages/legenda.js" /* webpackChunkName: "component---src-pages-legenda-js" */),
  "component---src-pages-milosc-js": () => import("./../../../src/pages/milosc.js" /* webpackChunkName: "component---src-pages-milosc-js" */),
  "component---src-pages-przyjazn-js": () => import("./../../../src/pages/przyjazn.js" /* webpackChunkName: "component---src-pages-przyjazn-js" */),
  "component---src-pages-smierc-js": () => import("./../../../src/pages/smierc.js" /* webpackChunkName: "component---src-pages-smierc-js" */),
  "component---src-pages-tworczosc-js": () => import("./../../../src/pages/tworczosc.js" /* webpackChunkName: "component---src-pages-tworczosc-js" */),
  "component---src-pages-warszawa-js": () => import("./../../../src/pages/warszawa.js" /* webpackChunkName: "component---src-pages-warszawa-js" */),
  "component---src-pages-wojna-js": () => import("./../../../src/pages/wojna.js" /* webpackChunkName: "component---src-pages-wojna-js" */),
  "component---src-pages-zrodla-js": () => import("./../../../src/pages/zrodla.js" /* webpackChunkName: "component---src-pages-zrodla-js" */)
}

