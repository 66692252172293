import React, { useState, useEffect, useRef } from "react"
import {
  Wrapper,
  CloseButton,
  Mask,
  PhotoContainer,
  Photo,
  PhotoDescription,
  PhotoAuthor,
} from "./styles"
import { AnimatePresence } from "framer-motion"
import { fadeVariant, unfoldVariant } from "./styles/animations";
import CloseBG from '../../../images/ModalClose.png'


const PhotoFullscreen = ({
  open,
  handlerClose,
  description,
  photoSrc,
  author,
}) => {
  const [topFade, setTopFade] = useState(false)
  const [bottomFade, setBottomFade] = useState(false)

  function escapeHandler({ key }) {
    if (key === "Escape") {
      return handlerClose()
    }
  }
  const mask = useRef();
  const photo = useRef();


  useEffect(() => {
    console.log(photo)
    window.addEventListener("keyup", escapeHandler)

    return () => {
      window.removeEventListener("keyup", escapeHandler)
    }
  }, [])

  const curtainCLick = e => {
    if (e.target !== mask.current) {
      return
    }
    return handlerClose()
  }


  return (
    <AnimatePresence>
      {open && (
        <Mask initial="exit" animate="enter" exit="exit" variants={fadeVariant}>
          <Wrapper
            onClick={e => {
              curtainCLick(e)
            }}
            initial="exit"
            ref={mask}
            animate="enter"
            exit="exit"
            variants={unfoldVariant}
            isPhoto={1}
          >
              <CloseButton tabindex="0" onClick={handlerClose} bg={CloseBG}></CloseButton>
            <PhotoContainer>
              <Photo style={{maxWidth: 'none'}} src={photoSrc}></Photo>
              <PhotoDescription>{description}</PhotoDescription>
              <PhotoAuthor>{author}</PhotoAuthor>
            </PhotoContainer>
          </Wrapper>
        </Mask>
      )}
    </AnimatePresence>
  )
}

export default PhotoFullscreen
