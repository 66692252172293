import styled, { keyframes } from "styled-components"

import { Link } from "gatsby"

export const PRLink = styled.a`
  display: flex;
  width: 90px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 8vh;
`

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background: black;
  background: ${({ bg }) => bg};
  background-size: cover;
  background-position: center center;
  transition: 0.3s;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`

export const Nav = styled.nav`
  width: 15%;
  height: 100%;
  background-color: #040503;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  position: relative;
  background-size: 150%;
`
export const NavTitle = styled.h1`
  width: 100%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 3.2px;
  flex-direction: column;
  color: white;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 14vh;
  text-transform: uppercase;
  @media (max-width: 1100px) {
    font-size: 0.85rem;
  }
`
export const NavSubTitle = styled.h2`
  width: 100%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 3.2px;
  flex-direction: column;
  color: white;
  align-items: center;
  text-transform: uppercase;
  line-height: 0.8;
  color: #fdfdfd;
  opacity: 0.62;
  position: relative;
  img {
    position: absolute;
    z-index: -1;
    top: -35px;
  }
`

export const TitleElement = styled.span`
  margin-top: 0.625rem;
  position: relative;
  &:before {
    content: "";
    height: 0.1875rem;
    width: 1.25rem;
    background-color: #c3a063;
    position: absolute;
    left: -1.875rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    height: 0.1875rem;
    width: 1.25rem;
    background-color: #c3a063;
    position: absolute;
    right: -1.875rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const NavList = styled.ul`
  color: #fdfdfd;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 3.16px;
  margin-bottom: 8vh;
  @media (max-width: 1199px) {
    font-size: 0.85rem;
  }
`

export const NavElement = styled.li`
  width: 100%;
  margin: 8% 0;
  padding-right: 1vw;
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  opacity: 0.62;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 8px 0 8px 2vw;
  @media (max-width: 1199px) {
    padding-left: 1.5vw;
  }
  &.active {
    background: rgba(53, 2, 9, 0.9);
  }
  &:hover {
    opacity: 1;
  }
`

export const Logo = styled.img`
  width: 100%;
  height: auto;
`

export const Content = styled.div`
  width: 85%;
  height: 100%;
`

export const TitlePage = styled.p`
  color: #f6f6f6;
  font-family: Montserrat;
  font-size: 0.6vw;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3.16px;
  position: absolute;
  right: -9.6vw;
  top: 16%;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 20vw;
  text-align: right;

  @media (min-width: 1600px) {
    right: -9.66vw;
  }
`

export const ContentScrollable = styled.div`
  width: 100%;
  /* padding: 0 5%; */
  padding-left: 5%;
  margin: 0 auto;
  height: 100%;
  position: relative;

  .slide {
    overflow: auto;
    position: relative;

    .simplebar-vertical {
      height: 35%;
      transform: translateY(120%);
      margin-right: 5%;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        transform: translateX(-50%);
        background-color: #fdfdfd;
      }
    }

    .simplebar-scrollbar {
      background: #f93737;
      &::before {
        display: none;
      }
    }
  }
`

export const ScrollContainer = styled.div`
  padding-right: 5rem;
`

export const ModalOpener = styled.button`
  background-color: transparent;
  opacity: 0.62;
  cursor: pointer;
  transition: 0.3s;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 3.16px;
  text-decoration: none;
  color: inherit;
  opacity: 0.62;
  transition: 0.3s;
  display: block;
  border: none;
  text-transform: uppercase;
`

const animationCover = keyframes`
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    z-index: -2;
  }
`

export const CoverImg = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 120;
  animation: ${animationCover} 3s forwards;
  opacity: 0;
`

export const InfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: calc(5% - 0.75rem);
  transform: translateY(80vh);
`

export const Icon = styled.div`
  border-radius: 50%;
  background-color: #fa3737;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 0.5rem;
    filter: invert(1);
  }
`
export const InfoDescription = styled.div`
  background: black;
  opacity: 0.8;
  color: white;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 200%;
  width: max-content;
  max-width: 40rem;

  &:after {
    content: "";
    position: absolute;
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: calc(50% + 0.25rem);
    right: -0.5rem;
  }
`
