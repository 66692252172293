import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import ModalWrapper from "./shared"
// import AudioDescription from "src/components/AudioDescription"
// import AudioPlayer from "src/components/AudioPlayer"
import PhotoFullscreen from "../PhotoFullscreen/desktop"

const Modal = ({
  children,
  open,
  handlerClose,
  photoSrc,
  author,
  description,
  title
}) => {
  return (
    <ModalWrapper
      open={open}
      handlerClose={handlerClose}
      photoSrc={photoSrc}
      author={author}
      description={description}
      title={title}
    >
      {children}
    </ModalWrapper>
  )
}

const Portal = ({
  children,
  open,
  handlerClose,
  photoSrc,
  photoFullscreen,
  author,
  description,
  title
}) => {
  let portalRoot

  const isPortalRoot = typeof document !== `undefined`
  if (isPortalRoot) {
    portalRoot = document.getElementById("portal")
  } else {
    return false
  }

  return ReactDOM.createPortal(
    photoFullscreen ? (
      <PhotoFullscreen
        open={open}
        handlerClose={handlerClose}
        photoSrc={photoSrc}
        author={author}
        description={description}
        
      />
    ) : (
      <Modal open={open} handlerClose={handlerClose} photoSrc={photoSrc} title={title}>
        {children}
      </Modal>
    ),
    portalRoot
  )
}

export default Portal
