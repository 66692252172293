import React, { useEffect, useRef, useState } from "react"
import {
  Content,
  Logo,
  Nav,
  NavTitle,
  TitleElement,
  NavLink,
  NavElement,
  NavList,
  NavSubTitle,
  Wrapper,
  ContentScrollable,
  ScrollContainer,
  CoverImg,
  PRLink,
  InfoIcon,
  Icon,
  InfoDescription,
} from "./styles"
import LogoPR from "src/images/logoPR24.png"
import microphone from "src/images/microphone.png"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import pagesList from "../pagesList"
import NavBg from "src/images/menu__bg.png"
import BG from "src/images/Baczynski_BG.png"
import LandingPageBG from "../../../images/LandingPageBG.jpg"
import BackgroundFace from "../../BackgroundFace"
import ModalButton from "../../ModalButton"
import RadioBG from "../../../images/RadioBaczynskiBG.png"
import { Link } from "gatsby"
import IconPNG from "../../../images/info.png"
import parse from "html-react-parser"

const getCover = pathname => {
  const indexCover = pagesList.findIndex(({ path }) => `/${path}` === pathname)
  return indexCover !== -1 && require(`images/covers/${indexCover + 1}.jpg`)
}

const getDescription = pathname => {
  if (pathname === "/") {
    return "Krzysztof Kamil Baczyński, Warszawa 1939. Fot. Andrzej Szypowski/East News <br/><br/> Grafika Krzysztofa Kamila Baczyńskiego, brak daty. Fot. Biblioteka Narodowa/polona.pl"
  } else {
    const index = pagesList.findIndex(
      ({ path }) => `/${path}` === pathname || `/${path}/` === pathname
    )
    return pagesList[index].description
  }
}

const Layout = ({ children, faceBg, title, pathname }) => {
  const scrollableNodeRef = useRef(null)
  const [coverPath, setCoverPath] = useState(null)
  const [description, setDescription] = useState(null)
  useEffect(() => {
    const element = scrollableNodeRef.current
    const scrollAction = e => {
      if (e.key == "ArrowDown") {
        element.scrollTop += 20
      }
      if (e.key == "ArrowUp") {
        element.scrollTop -= 20
      }
    }
    window.addEventListener("keydown", scrollAction, false)

    return () => window.removeEventListener("keydown", scrollAction, false)
  }, [scrollableNodeRef])

  useEffect(() => {
    setCoverPath(getCover(pathname))
    scrollableNodeRef.current &&
      scrollableNodeRef.current.scrollTo({
        top: 0,
        // behavior: "smooth",
      })
  }, [pathname])

  return (
    <>
      {coverPath && (
        <CoverImg src={coverPath} onAnimationEnd={() => setCoverPath(null)} />
      )}
      <Wrapper style={{ pointerEvents: coverPath ? "none" : "auto" }}>
        <Nav bg={NavBg} role="menu">
          {/* <Link to="/" style={{ width: "38%", height: "auto" }}>
            <Logo src={LogoPR} alt="Logo PR" />
          </Link> */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <NavTitle>
              Krzysztof Kamil <br /> <TitleElement>Baczyński</TitleElement>
            </NavTitle>
          </Link>
          <NavList>
            {pagesList.map((page, index) => (
              <NavElement key={"page-" + index}>
                <NavLink
                  aria-label={page.name}
                  role="menuitem"
                  activeClassName="active"
                  // activeStyle={{ opacity: "1" }}
                  fade="true"
                  to={`/${page.path}`}
                  partiallyActive={true}
                >
                  {`${index + 1}. `}
                  {page.name}
                </NavLink>
              </NavElement>
            ))}
            <NavElement>
              <NavLink
                aria-label="zrodla"
                role="menuitem"
                activeClassName="active"
                // activeStyle={{ opacity: "1" }}
                fade="true"
                to={`/zrodla`}
                partiallyActive={true}
              >
                Źródła
              </NavLink>
            </NavElement>
          </NavList>
          <ModalButton photo={RadioBG} title={"Radio Baczyński"}>
            <NavSubTitle>
              <img src={microphone} alt="Radio Baczyński" />
              <TitleElement>Radio</TitleElement>
              <br /> Baczyński
            </NavSubTitle>
          </ModalButton>
          <PRLink href="https://www.polskieradio24.pl">
            <Logo src={LogoPR} alt="Logo Polskiego Radia" />
          </PRLink>
        </Nav>
        <Content
          style={{
            backgroundImage: `${
              pathname === "/" ? `url(${LandingPageBG})` : `url(${BG})`
            }`,
            backgroundSize: `${pathname === "/" ? "cover" : "120%"}`,
            backgroundPositionX: `${pathname === "/" ? "left" : "-20rem"}`,
            backgroundPositionY: "bottom",
          }}
        >
          <ContentScrollable>
            <SimpleBar
              className="slide"
              style={{ maxHeight: "100vh" }}
              scrollableNodeProps={{ ref: scrollableNodeRef }}
            >
              <BackgroundFace src={faceBg} />
              <ScrollContainer
                style={{
                  padding: "10rem 0",
                  paddingRight: "10rem",
                  paddingTop: `${pathname === "/" ? "6rem" : "10rem"}`,
                }}
              >
                {children}
              </ScrollContainer>
            </SimpleBar>
            <InfoIcon>
              <Icon
                onMouseEnter={() => setDescription(getDescription(pathname))}
                onMouseLeave={() => setDescription(null)}
              >
                <img src={IconPNG} />
              </Icon>
              {description && (
                <InfoDescription>{parse(description)}</InfoDescription>
              )}
            </InfoIcon>
          </ContentScrollable>
        </Content>
      </Wrapper>
    </>
  )
}

export default Layout
