import styled from "styled-components"

export const Button = styled.div`
 
  border: none;
 
  transition: 0.3s;
  cursor: pointer;

 
  &:hover {
  
  }
`

export const Title = styled.span`
width: 20vw;
padding: 1rem;
display: flex;
justify-content: center;
background-color: #1e1e1e;

font-family: Montserrat;
font-size: 2rem;
font-weight: 700;

text-transform: uppercase;
color: #e9d1bb;
@media (max-width: 768px) {
  display: none;
}


`


export const Content = styled.p`
font-size: 1rem;
margin-top: 5rem;
color: #0f0f0f;
color: #fdfdfd;
font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 1.5;
text-align: left;
color: #0f0f0f;
letter-spacing: 0.8px;
`
export const SeeMore = styled.a`
width: 377px;
height: 47px;
border-radius: 5px;
background-color: #f93737;
display: flex;
justify-content: center;
align-items: center;
margin: 5vh auto 5vh;
font-family: Montserrat;
font-size: 20px;
font-weight: 500;
text-decoration: none;

color: #f3e7d9;
@media (max-width: 768px) {
  width: fit-content;
  padding: 0 1rem;
  u {
    display: none;


  }
}

`


export const WrapperContent = styled.div`
  @media(max-width: 768px){
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
`;