import React, { useState, useEffect, useCallback } from "react"
import { FacePhoto } from "./styles"

const BackgroundFace = ({ src = "" }) => {
  const [readyPhoto, setReadyPhoto] = useState(null)

  useEffect(() => {
    const idTimeout = setTimeout(() => setReadyPhoto(src), 1000)
    return () => clearTimeout(idTimeout)
  }, [src])




  return <FacePhoto style={{opacity: src!=='url()'? '100%':'0'}} src={readyPhoto}></FacePhoto>
}

export default BackgroundFace
