import styled, { css } from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { motion } from "framer-motion"
import NavBg from "../../../images/menu__bg.png"

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  width: 100%;
  margin-bottom: 6vh;
  & > * {
    margin: 0px 1.5rem;
  }
`

export const PRLink = styled.a`
  /* width: 115px; */
`

export const Logo = styled.img`
  width: 100%;
  height: auto;
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: ${({ bg }) => bg};
  background-size: cover;
  background-position: center;
  position: relative;

`

export const MenuButton = styled.div`
  position: fixed;
  top: 5%;
  left: 5%;
  z-index: 3;

  ${({ isMenuVisible }) =>
    isMenuVisible &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        background-color: rgba(200, 200, 200, 0.8);
        border-radius: 50%;
        z-index: -1;
        transform: translate(-5px, -9px);
      }
    `}
`

export const WrapperScroll = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
`

export const Nav = styled(motion.nav)`
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${NavBg});
  background-size: 250%;
  background-position-x: 23%;
  background-position-y: 40%;
  background-color: #040503;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 2;
`

export const NavList = styled.ul`
  color: #fdfdfd;
  font-family: Montserrat;
  font-size: clamp(1rem, 3.5vw, 2rem);
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 4.37px;
  text-align: left;
`

export const NavElement = styled.li`
  padding: 9% 0;
  width: 100%;
`

export const NavLink = styled(AniLink)`
  text-decoration: none;
  color: inherit;
  opacity: 0.62;
  width: 80%;
`

export const NavTitle = styled.h1`
  width: 100%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 3.2px;
  flex-direction: column;
  color: white;
  align-items: center;
  margin-top: 8%;
  margin-bottom: 10%;
  text-transform: uppercase;
`

export const TitleElement = styled.span`
  margin-top: 0.625rem;
  position: relative;
  &:before {
    content: "";
    height: 0.1875rem;
    width: 1.25rem;
    background-color: #c3a063;
    position: absolute;
    left: -1.875rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    height: 0.1875rem;
    width: 1.25rem;
    background-color: #c3a063;
    position: absolute;
    right: -1.875rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const NavSubTitle = styled.h2`
  width: 100%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 3.2px;
  flex-direction: column;
  color: white;
  align-items: center;
  text-transform: uppercase;
  line-height: 0.8;
  color: #fdfdfd;
  opacity: 0.62;
  position: relative;
  img {
    position: absolute;
    z-index: -1;
    top: -35px;
  }
`
