import React, { useState, useEffect } from "react"
import Modal from "src/components/Modal/Modal"
import { Button, Title, Content, SeeMore,WrapperContent } from "./styles"
import parse from "html-react-parser"
import { useArticles } from "src/hooks";
import radioArrows from '../../images/radioarrows.png'

const ModalButton = ({
  title = "",
  lead = "",
  photo = "",
  audio = [],
  style = {},
  children,
} = {}) => {
  const [isOpen, setOpen] = useState(false)

  const articles = useArticles({ articleCategory: 9066 })
  const { list } = articles
  
  return (
    <>
      <Button tabindex="0" onClick={() => setOpen(true)} style={style}>
        {children}
      </Button>

      <Modal
        open={isOpen}
        photoSrc={photo}
        handlerClose={() => {
          setOpen(false)
        }}
        audioArray={audio}
        title={title}
      >
        <Title>{title}</Title>
        {lead && lead.length > 0 && (
          <p style={{ color: "#020417", display: "block" }}>{lead}</p>
        )}
        <WrapperContent>
       <Content> {list[1]?.Content  &&  parse(list[1].Content)} </Content>
          <SeeMore
            href="https://moje.polskieradio.pl/#/94?gsid=84"
            target="_blank"
          >
            POSŁUCHAJ{" "}
            <u
              style={{
                backgroundImage: `url(${radioArrows})`,
                width: '35px',
                height: '20px',
                textDecoration: "none",
                fontWeight: "900",
                marginLeft: "2rem",
              }}
            >
              
            </u>
          </SeeMore>
        </WrapperContent>
      </Modal>
    </>
  )
}

export default ModalButton
