const pagesList = [
  {
    path: "legenda",
    name: "Legenda",
    description:
      "Popiersie Krzysztofa Kamila Baczyńskiego na Skwerze Harcerskim im. Szarych Szeregów w Kielcach. Fot. Monkpress/East News",
  },
  {
    path: "milosc",
    name: "Miłość",
    description:
      "Barbara Baczyńska w dniu ślubu. Warszawa, 3 czerwca 1942. Fot. Andrzej Wiernicki/East News",
  },
  {
    path: "przyjazn",
    name: "Przyjaźń",
    description:
      "Krzysztof Kamil Baczyński w latach szkolnych. Warszawa 1937. Fot. Muzeum Literatury/East News",
  },
  {
    path: "tworczosc",
    name: "Twórczość",
    description:
      "Krzysztof Kamil Baczyński jako maturzysta. Warszawa 1939. Fot. PAP/CAF",
  },
  {
    path: "wojna",
    name: "Wojna",
    description:
      "Krzysztof Kamil Baczyński z żoną Barbarą na warszawskiej ulicy w 1943 roku. Fot. Muzeum Literatury/East News",
  },
  {
    path: "smierc",
    name: "Śmierć",
    description:
      "Krzysztof Kamil Baczyński. Warszawa, lata 40. Fot. Andrzej Szypowski/East News",
  },
  {
    path: "warszawa",
    name: "Warszawa",
    description:
      "Skrzyżowanie ulicy Nowy Świat z Alejami Jerozolimskimi. Warszawa 1930-1934. Fot. Narodowe Archiwum Cyfrowe/Jan Binek",
  },
]

export default pagesList
