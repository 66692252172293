const { default: Axios } = require("axios")

class API {
  static getArticlesByCategory({
    pageNumber = 0,
    pageSize = 0,
    categoryTags = 0,
  } = {}) {
    try {
      return Axios({
        method: "GET",
        url: `https://apipr.polskieradio.pl/api/articlesWithContent?PageNumber=${pageNumber}&pageSize=${pageSize}&categoryTags=${categoryTags}`,
      })
    } catch (error) {
      throw error
    }
  }
}

export default API
