import React, { useState } from "react"
import { MenuButton, Wrapper, WrapperScroll } from "./styles"
import HamburgerMenu from "react-hamburger-menu"
import Menu from "./Menu"
import { AnimatePresence } from "framer-motion"
import BackgroundFace from "../../BackgroundFace"
import LandingBgMobile from "../../../images/MobileLandingPageBG.png"

const Layout = ({ children, bg, faceBg, pathname }) => {
  const [isMenuVisible, setMenuVisible] = useState(false)

  const handleMenuToggle = e => {
    setMenuVisible(!isMenuVisible)
  }

  return (
    <>
      <Wrapper bg={pathname === "/" ? `url(${LandingBgMobile})` : bg}>
        <MenuButton isMenuVisible={isMenuVisible}>
          <HamburgerMenu
            isOpen={isMenuVisible}
            menuClicked={() => setMenuVisible(!isMenuVisible)}
            width={30}
            height={20}
            strokeWidth={isMenuVisible ? 2 : 3}
            rotate={0}
            color={isMenuVisible ? "red" : "#1e1e1e"}
            borderRadius={0}
            animationDuration={0.5}
          />
        </MenuButton>
        <WrapperScroll>
          <BackgroundFace src={faceBg}></BackgroundFace>

          {children}
        </WrapperScroll>
      </Wrapper>
      <AnimatePresence>
        {isMenuVisible && <Menu handleMenuToggle={handleMenuToggle} />}
      </AnimatePresence>
    </>
  )
}

export default Layout
