import styled from "styled-components"

export const FacePhoto = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  background-image: url(${props => props.src});
  background-size: cover;
  @media (max-width: 768px) {
    width: 72%;
    height: 100vh;
    background-size: 150%;
    background-repeat: no-repeat;
  }
`
