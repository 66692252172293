import styled from "styled-components"
import { motion } from "framer-motion"
import SimpleBar from "simplebar-react"

export const Mask = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  *:focus{
    outline:none;
  }
`

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: ${({ isPhoto }) => (isPhoto ? "8%" : "0")};

  transform: translateX(-50%, -50%);
  background-color: #e3e4dd;
  height: 100vh;
  width: ${({ isPhoto }) => (isPhoto ? "89vw" : "85%")};
  z-index: 2;
  display: flex;
  flex-direction: column;
  ${({ isPhoto }) =>
    isPhoto &&
    `
    display: grid;
    grid-template-columns: 5fr 3fr;
  `};

  @media (max-width: 1024px) {
    right: 0;
    width: 100%;

    ${({ isPhoto }) =>
      isPhoto &&
      `
    display: flex;
    justify-content: space-between;
    overflow: auto;
    `}
  }
`

export const Text = styled.div`
  color: #020417;
  font-family: "Open Sans";
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 27px;
  padding-right: 4vw;

  /* h1 {
    color: #020417;
    font-family: "Abril Fatface";
    font-size: 3.2rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 56.39px;
  } */
`

export const CloseButton = styled.button`
  
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 4rem;
  width: 3rem;
  height: 3rem;
  background-image: url(${props=>props.bg});
  background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
    @media (max-width: 768px) {
      top: 3.5rem;
    }

`

export const CustomSidebar = styled(SimpleBar)`
  max-height: 80vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;

  @media (min-width: 1024px) {
    .simplebar-vertical {
      height: 85%;
      margin-top: auto;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        transform: translateX(-50%);
        background-color: #040c17;
      }
    }

    .simplebar-scrollbar {
      background: #f93737;
      border-radius: 10px;
      &::before {
        display: none;
      }
    }
  }
`

export const TopFade = styled(motion.div)`
  position: absolute;
  height: 20%;
  width: 100%;
  background-color: black;
  z-index: 1;
  background: linear-gradient(to top, transparent 0%, #e3e4dd 100%);
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: none;
  top: -1px; //gap fix
  @media (max-width: 768px) {
    display: none;
  }
  
`

export const BottomFade = styled(motion.div)`
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 0;
  background-color: black;
  z-index: 1;
  background: linear-gradient(to bottom, transparent 0%, #e3e4dd 100%);
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: none;
  bottom: -1px; //gap fix
  @media (max-width: 768px) {
    display: none;
  }
`

export const PaddingWrapper = styled.div`
  padding: 5rem;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 4rem 2rem;
    margin-top: 7rem;
  }
`

export const FadeWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Photo = styled.img`
max-height: 60vh;
  object-fit: cover;
  margin-top: 14vh;
  max-width: 42.5vw;


  @media (max-width: 1024px) {
    margin-top: 0;
    max-width: 80vw;

  }
`

export const LogoMobile = styled.img`
  width: 18%;
  filter: brightness(0%);

  margin: 3rem auto 5rem;
  margin-left: 50%;
  margin-right: 50%;
  transform: translate(-60%, 0);
`
export const ModalFlex = styled.div`
display: flex;
@media (max-width: 768px) {
  flex-direction: column-reverse;
}
`

export const MobileTitle = styled.span`
padding: 1rem;
display: flex;
justify-content: center;
background-color: #1e1e1e;

font-family: Montserrat;
font-size: 1.8rem;
font-weight: 700;

text-transform: uppercase;
color: #e9d1bb;
position: absolute;
width: 70vw;
    top: 3rem;
@media (min-width: 769px) {
  display: none;
}

`
