import { useEffect, useState, useMemo } from "react"

import API from "src/API"

export const useArticles = ({ articleCategory = 0 } = {}) => {
  const [articles, setArticles] = useState([])
  const [page, setPage] = useState(1)

  useMemo(() => {
    API.getArticlesByCategory({
      pageNumber: page,
      pageSize: 100,
      categoryTags: articleCategory,
    }).then(response => {
      if (response.data.ApiArticles)
        setArticles([...articles, ...response.data.ApiArticles])
    })
  }, [page])

  const fetchNewPage = () => {
    return setPage(prevPage => prevPage + 1)
  }

  return { list: articles, fetchNewPage }
}
