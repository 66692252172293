import React, { useState, useEffect } from "react"
import "./reset.css"
import DesktopLayout from "./desktop"
import MobileLayout from "./mobile"
import { useLocation } from "@reach/router"
import BG from "src/images/Baczynski_BG.png"
import Face from "src/images/faces/LEGENDA.png"

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.innerWidth < 1024 && setIsMobile(true)
    }
  }, [])

  const getFaceBg = () => {
    switch (pathname.replace(/\//g, "")) {
      case "legenda":
        return require("images/faces/LEGENDA.png")
      case "milosc":
        return require("images/faces/MILOSC.png")
      case "przyjazn":
        return require("images/faces/PRZYJAZN.png")
      case "tworczosc":
        return require("images/faces/TWORCZOSC.png")
      case "wojna":
        return require("images/faces/WOJNA.png")
      case "smierc":
        return require("images/faces/SMIERC.png")
      case "warszawa":
        return require("images/faces/WARSZAWA.png")
      default:
        return `url()`
    }
  }

  const getTitle = () => {
    switch (pathname.replace(/\//g, "")) {
      case "legenda":
        return "Legenda"
      case "milosc":
        return "Miłość"
      case "przyjazn":
        return "Przyjaźń"
      case "tworczosc":
        return "Twórczość"
      case "wojna":
        return "Wojna"
      case "smierc":
        return "Śmierć"
      default:
        return "Krzysztof Kamil Baczyński"
    }
  }

  if (isMobile)
    return (
      <MobileLayout
        children={children}
        bg={`url(${BG})`}
        faceBg={getFaceBg()}
        pathname={pathname}
      />
    )
  return (
    <DesktopLayout
      children={children}
      faceBg={getFaceBg()}
      title={getTitle()}
      pathname={pathname}
    />
  )
}

export default Layout
